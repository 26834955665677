.reviews-section {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  min-height: 1000px;
  background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676616673/ukodesh-media/Rectangle_39_dbkpdi.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -1px;
  padding: 0 120px;
  transition: all ease-in-out 0.2s;


  @media screen and (max-width:1280px) {
    min-height: 1980px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width:1024px) {
    min-height: 1980px;
    background-size: cover;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width:430px) {
    min-height: 1980px;
    padding: 0 48px;
  }

  @media screen and (max-width:320px) {
    min-height: 1980px;
    padding: 0 24px;
  }

  .title-reviews {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    margin: 50px 0;
    text-align: center;
  }

  .row-one {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
    }
  }
  .row-two {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    @media screen and (max-width: 1280px) {
      flex-direction: column;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
    }
  }
}

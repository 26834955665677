.business-benefits {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .business-details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    background: #e9f0f4;
    width: 100%;
  }
}

.details-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1243px;
  flex-direction: column;
}

.benefit-card {
  width: 100%;
  max-width: 226px;
  text-align: center;

  .benefit-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.benefit-card:not(:last-child) {
  margin-right: 120px;

  @media screen and (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 50px;
  }
}

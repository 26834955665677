
.feature-card {
    width: 100%;
    max-width: 226px;
    text-align: center;
    margin-top: 60px;
  
    .feature-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img{
        margin-bottom: 12px;
      }

      span{
        margin-top: 10px;
      }
    }
  }
  
  .feature-card:not(:last-child) {
  
    @media screen and (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .features-wrapper-cert{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
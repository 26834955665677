.contact-business {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding-bottom: 80px;
    box-sizing: border-box;
  
    @media screen and (max-width: 912px) {
      padding: 80px 48px;
    }
  
    .title-wrapper {
      width: 100%;
      max-width: 700px;
      text-align: center;
    }
  
    .inner-wrapper {
      display: flex;
      width: 100%;
      max-width: 700px;
  
      @media screen and (max-width: 912px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
  
      .left-side {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
  
        @media screen and (max-width: 912px) {
          align-items: center;
          text-align: center;
        }
  
        .media-wrapper {
          .detail-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media screen and (max-width: 912px) {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
  
      .right-side {
        width: 100%;
        max-width: 350px;
      }
    }
  }
  
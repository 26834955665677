.small-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
    background: #447294;
    width: 100%;
    min-height: 200px;

    .container-wrapper {
      width: 100%;
      max-width: 600px;
      text-align: center;
      font-size: 24px;
    }
  }
.header-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  left: 0;
  top: 0;
  background: white;
  z-index: 999;

  @media screen and (max-width: 1280px) {
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 768px) {
    transition: all ease-in-out 0.2s;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1138px;
    padding: 24px 0;
    box-sizing: border-box;

    @media screen and (max-width: 912px) {
      padding: 24px;
    }

    .logo-section {
      width: fit-content;
      transition: all ease-in-out 0.4s;

      @media screen and (max-width: 430px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        transition: all ease-in-out 0.4s;
      }

      img {
        width: 96px;
        transition: all ease-in-out 0.4s;
      }
    }

    .toggle-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .navbar-section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .buttons-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: auto;
      }

      @media screen and (max-width: 1124px) {
        display: none;
        transition: all ease-in-out 0.2s;
      }
    }

    .burger-wrapper {
      display: none;
      @media screen and (max-width: 1124px) {
        display: flex;
        transition: all ease-in-out 0.2s;
      }
    }
  }
}

.full-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 100vh;
  width: 100%;
}

.nav-drop {
  overflow: hidden;
  .nav-dropbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }
}

.drop-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
}

.drop-content a {
  float: none;
  padding: 16px 40px 16px 14px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.drop-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.nav-drop:hover .drop-content {
  display: block;
}

.switch-icon {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 8px;

  span {
    width: 8px;
    height: 2px;
    transition: 0.1s ease-in-out;
    background: #2e3035;
  }
}

.ml-4 {
  margin-left: 4px;
}

.mr-20 {
  margin-right: 20px;
}

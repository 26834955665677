.languages-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 800px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 180px;
    text-align: center;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      margin-top: 120px;
    }

  }

  .flags-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .flag-col:not(:last-child){
      margin-right: 24px;
    }

    .flag-col{
      img{
        margin-bottom: 4px;
      }
    }

  }

  .cards-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 40px;
    background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676066643/ukodesh-media/Vector_nvuwqr.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media screen and (max-width: 1024px) {
      background-size: 100% 20%;
      background-repeat: space;
      background-position: center;
    }

    @media screen and (max-width: 430px) {
      background-size: auto;
      background-repeat: space;
      background-position: top;
      padding: 0 30px;
    }
  }
}

.appBanner-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 650px;

  @media screen and (max-width:1024px) {
    padding: 34px 0;
    min-height: 0;
  }

  .column {
    display: flex;
    width: 100%;
  }

  .column-direction {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      justify-content: center;
      align-items: center;
    }
  }

  .banner-left {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  .banner-left-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width:1280px) {
      max-width: 320px;

    }

    @media screen and (max-width: 1024px) {
      justify-content: center;
      align-items: center;
    }
  }

  .banner-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;

    img {
      width: inherit;
    }
  }

  .app-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:768px) {
      flex-direction: column;
    }

    img {
      width: 128px;
    }

    a:not(:last-child) {
      margin-right: 20px;

      @media screen and (max-width:768px) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  .mobile-app{
    width: 230px;
  }
}

.banner-inner{
  display: flex;
  
  width: 100%;
  max-width: 1243px;
}

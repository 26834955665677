.business-hero {
  display: flex;
  width: 100%;
  padding: 0 120px;
  box-sizing: border-box;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1280px) {
    padding: 0 80px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (min-width: 912px) {
    padding: 80px 80px 0 80px;
    transition: all ease-in-out 0.2s;
  }

  .hero-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    width: 100%;
    max-width: 1273px;

    @media screen and (max-width: 912px) {
      flex-direction: column;
      justify-content: center;
    }

    .left-side {

        @media screen and (max-width: 912px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            margin-bottom: 40px;
          }

      img {
        width: 420px;

        @media screen and (max-width: 1292px) {
          width: 380px;
          transition: all ease-in-out 0.2s;
        }

        @media screen and (max-width: 1122px) {
          width: 340px;
          transition: all ease-in-out 0.2s;
        }

        @media screen and (max-width: 1024px) {
          width: 320px;
          transition: all ease-in-out 0.2s;
        }

        @media screen and (max-width: 912px) {
            width: 280px;
            transition: all ease-in-out 0.2s;
          }

        @media screen and (max-width: 425px) {
          width: 280px;
          transition: all ease-in-out 0.2s;
        }

        @media screen and (max-width: 375px) {
          width: 200px;
          transition: all ease-in-out 0.2s;
        }
      }
    }
  }

  .right-side {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 60%;

    @media screen and (max-width: 1024px) {
        width: 55%;
      }

    @media screen and (max-width: 912px) {
      align-items: center;
      text-align: center;
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.practices-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676249339/ukodesh-media/Group_112_xjirzi.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 19%;
  margin-bottom: 100px;

  @media screen and (max-width: 1440px) {
    background-size: 19%;
  }

  @media screen and (max-width: 912px) {
    background-size: 45%;
  }

  @media screen and (min-width: 1440px) {
    background-size: 18%;
  }
}

.tablet-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 96px;

  @media screen and (max-width: 912px) {
    margin-right: 0;
    margin-bottom: 48px;
  }

  .tablet-girl {
    border-radius: 80px;
    width: 80%;

    @media screen and (max-width: 430px) {
        width: 70%;
      }
  }

  .badge-level {
    width: 60%;
    margin-top: -60px;
    margin-left: -100px;

    @media screen and (max-width: 430px) {
      margin-top: -40px;
      margin-left: 0;
    }
  }
}

.practices {
  width: 35%;

  @media screen and (max-width: 912px) {
    width: 320px;
  }

  @media screen and (max-width: 375px) {
    width: 260px;
  }
}

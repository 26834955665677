.contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
  border-radius: 24px;
  height: 100%;
  padding: 24px 36px;
  box-sizing: border-box;
  margin: 18px 0;

  @media screen and (max-width: 912px) {
    margin-top: 20px;
    text-align: center;
  }

  .inner-wrapper {
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 912px) {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  form {
    .formInput {
      display: flex;
      width: 100%;
      flex-direction: column;

      select{
        outline: none;
        border: none;
        background: #ececec;
        border-radius: 4px;
        padding: 4px;
        padding-left: 10px;
        height: 30px;
        @media screen and (max-width: 912px) {
          width: 100%;
        }
      }
      select:focus {
        outline: none;
      }
      select:invalid[focused="true"] {
        border: 1px solid red;
      }

      select:invalid[focused="true"] ~ span {
        font-size: 12px;
        color: red;
        display: block;
      }

      input {
        outline: none;
        border: none;
        background: #ececec;
        border-radius: 4px;
        padding: 4px;
        padding-left: 10px;
        height: 30px;
        @media screen and (max-width: 912px) {
          width: 100%;
        }
      }
      input:focus {
        outline: none;
      }
      input:invalid[focused="true"] {
        border: 1px solid red;
      }

      input:invalid[focused="true"] ~ span {
        font-size: 12px;
        color: red;
        display: block;
      }
    }
  }
  .submit-btn {
    border: none;
    width: 100%;
    outline: none;
    border-radius: 12px;
    height: 48px;
    color: white;
    cursor: pointer;
  }

  .blue-bg{
    background: #0075ff;
  }
  
  .blue-light-bg{
    background: #5a80ac;
    cursor: default;
  }
}

.err-msg {
  font-size: 12px;
  padding-top: 3px;
  color: red;
  display: none;
}

.agree-terms{
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

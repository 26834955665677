.skills-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  background: linear-gradient(#fff, #f5f3fe);

  @media screen and (max-width:425px) {
    padding: 0 40px 80px 40px;
  }

  .skills-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.girl-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 80px;
  height: 0;
  margin-top: 100px;
  box-sizing: border-box;

  @media screen and (max-width: 912px) {
    margin-top: 160px;
  }

  @media screen and (max-width: 425px) {
    margin-top: 120px;
  }


  img {
    width: 38%;
    margin-left: 140px;

    @media screen and (max-width: 1440px) {
      width: 38%;
    }

    @media screen and (min-width: 1440px) {
      width: 389px;
      margin-left: 120px;
    }

    @media screen and (max-width: 1024px) {
      width: 50%;
      margin-left: 120px;
    }

    @media screen and (max-width: 912px) {
      width: 280px;
      box-sizing: border-box;
      margin-left: 80px;
    }
    @media screen and (max-width: 325px) {
      width: 240px;
      box-sizing: border-box;
      margin-left: 80px;
    }
  }
}

.business-partners{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width:912px) {
        display: none;
    }
}
.top-bar {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #3c495c;

    @media screen and (max-width:435px) {
      justify-content: center;;
      height: auto;
      padding: 12px 6px;
      flex-direction: column;
      
      .medium{
        display: none;
      }

      .mb-2{
        margin-bottom: 5px;
      }
    }
  }
.footer-section {
  display: flex;
  background: #3c495c;
  padding: 50px 80px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-section {
    width: fit-content;

    @media screen and (max-width:1024px) {
      width: auto;
    }

    img {
      width: 96px;
      filter: invert(1);
    }
  }

  .contact-details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @media screen and (max-width:1024px) {
      align-items: center;
    justify-content: center;
    }
  }

  .social-network {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      margin-right: 18px;
    }
  }

  .links-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .buttons-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: -moz-fit-content;
    width: fit-content;
    width: auto;
  }
}

.footer-wrapper{
  max-width: 1243px;
  width: 100%;

  @media screen and (max-width:1024px) {
    text-align: center;
  }
}

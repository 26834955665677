.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.button-level {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 120px;
    width: 120px;
    border-radius: 1000px;
    border: 6px solid #fff;
    transition: all ease-in-out 0.2s;
    margin-bottom: 10px;

    span {
        font-size: 32px;
        font-weight: bold;
    }
}

.activeButton {
    border: 6px solid #fff;
    transition: all ease-in-out 0.2s;


}

.button-wrapper:not(:last-child) {
    margin-right: 20px;
    
    @media screen and (max-width:1024px) {
        margin-right: 0px;
    }
}
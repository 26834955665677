.business-features {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width:912px) {
    padding: 0 24px;
    box-sizing: border-box;
  }

  .features-title {
    display: flex;
    align-items: center;
    justify-content: center;

    .title-wrapper{
        width: 100%;
        max-width: 800px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
    }
  }

  .features-details{
    display: flex;
    align-items: center;
    justify-content: center;

    .details-wrapper{
        width: 100%;
        max-width: 1243px;

        .left-side{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .right-side{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
  }
}

.inner-ft-details-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;

    @media screen and (max-width:1024px) {
        align-items: center;
        text-align: center;
        margin-top: 20px;
    }
}

.ft-reversed{
    display: flex;

    @media screen and (max-width:1024px) {
        margin-top: 30px;
        flex-direction: column-reverse;
    }
}

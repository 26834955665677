.levels-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 660px;
  flex-direction: column;
  background: url("https://res.cloudinary.com/droimgtqi/image/upload/v1676248239/ukodesh-media/Group_114_wvvudy.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 20%;

  @media screen and (max-width: 1440px) {
    background-size: 20%;
  }

  @media screen and (min-width: 1440px) {
    background-size: 11%;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 48px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 912px) {
    min-height: 550px;
    background-size: 30%;
  }

  @media screen and (max-width: 430px) {
    padding: 0;
    box-sizing: border-box;
  }

  .levels-home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0075ff;
    border-radius: 80px;
    max-width: 1024px;
    width: 100%;
    height: 423px;
    padding: 50px 100px;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }

    @media screen and (max-width: 912px) {
      width: 100%;
    }

    @media screen and (max-width: 430px) {
      border-radius: 0;
    }

    .active-bar-home {
      background: #004fac;
      transition: all ease-in-out 0.2s;

      @media screen and (max-width: 1024px) {
        background: #0075ff;
      }
    }
    .unactive-bar-home {
      background: #0075ff;
      transition: all ease-in-out 0.2s;
    }

    .active-element-home {
      background: #fff;
      span {
        color: #0075ff;
      }
    }

    .unactive-element-home {
      background: #c5e6ff;
      span {
        color: #6398ff;
      }
    }

    .active-selector-home {
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: #ff5353;
    }

    .unactive-selector-home {
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: #0075ff;
    }
  }
}

.business-work {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  .business-details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    background: #e9f0f4;
    width: 100%;
  }
}

.image-featureT {
  width: auto;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 768px) {
    width: 60%;
    transition: all ease-in-out 0.2s;
  }
}

.carousel{
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.inner{
    white-space: nowrap;
    transition: transform 0.3s;
    max-width: 320px;

}

.carousel-item{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;
}

.indicators{
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        polyline{
            stroke: #fff;
        }
    }
}

.left{margin-right: 4px;}
.right{margin-left: 4px;}

.carousel-title{
    font-size: 24px;
    color: white;
}

.levels-footer{
    padding: 0;
    width: 100%;
    white-space: initial;
}
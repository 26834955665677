#certification{
    .landing-sells {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .hero-banner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            padding-top: 24px;

            .inner-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;

                @media screen and (max-width:1024px) {
                    flex-direction: column-reverse;
                    text-align: center;
                }

                .left-side {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    .inner-text {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;

                        @media screen and (max-width:1024px) {
                            align-items: center;
                            text-align: center;
                        }

                        .title {
                            font-size: 32px;

                            @media screen and (max-width:1024px) {
                                font-size: 24px;
                            }

                            @media screen and (max-width:430px) {
                                font-size: 18px;
                            }
                        }
                    }

                }

                .right-side {
                    .img-banner {
                        max-width: 480px;

                        @media screen and (max-width:1024px) {
                            margin-bottom: 24px;
                        }

                        @media screen and (max-width:430px) {
                            max-width: 320px;
                        }
                    }
                }
            }

            .partners-line {
                display: flex;
                align-items: center;
                width: 100%;
                flex-direction: row;

                img{
                    width: 180px;
                }

                img:not(:last-child){
                    margin-right: 32px;

                    @media screen and (max-width:1024px) {
                        margin-bottom: 18px;
                    }

                    @media screen and (max-width:430px) {
                        margin-right: 0;
                    }
                }

                @media screen and (max-width:1024px) {
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        .assessment-section {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            padding: 60px 0;
            background: url('https://res.cloudinary.com/droimgtqi/image/upload/v1676249339/ukodesh-media/Group_112_xjirzi.svg');
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;

            @media screen and (max-width:1024px) {
                background-size: 300px;
            }

            @media screen and (max-width:430px) {
                background-size: 200px;
                background-position: top left;
            }

            .assessment-wrapper {
                display: flex;
                padding: 40px 0;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;

                @media screen and (max-width:1024px) {
                    flex-direction: column;
                }

                .left-side {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .img-assessment {
                        width: 320px;
                    }
                }

                .right-side {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    flex-direction: column;

                
                    .inner-text {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        max-width: 400px;
                        text-align: center;

                        @media screen and (max-width:1024px) {
                            margin-top: 12px;
                        }

                        @media screen and (max-width:430px) {
                            text-align: center;
                        }
                    }

                    .card-form {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 40px;
                        border-radius: 24px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        margin-top: 40px;
                        margin-bottom: 12px;
                        width: 260px;
                        background: #0075ff;

                        @media screen and (max-width:430px) {
                            width: 200px;
                        }

                        select {
                            background: #ffffff52;
                            border: none;
                            border-radius: 4px;
                            height: 34px;
                            outline: none;
                            padding: 4px 4px 4px 10px;
                            margin-bottom: 12px;
                            color: white;
                            width: 100%;
                        }
                    }
                }
            }

            .inner-text {
                text-align: center;
            }
        }

        .features-section {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            padding: 60px 0;
            background: url('https://res.cloudinary.com/droimgtqi/image/upload/v1676248239/ukodesh-media/Group_114_wvvudy.svg');
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;

            @media screen and (max-width:1024px) {
                background-size: 300px;
            }

            @media screen and (max-width:430px) {
                background-size: 200px;
                background-position: center right;
            }

            .landing-wrapper {
                @media screen and (max-width:1024px) {
                    max-width: 600px;
                    text-align: center;
                }

                @media screen and (max-width:768px) {
                    max-width: 250px;
                    text-align: center;
                }

            }
        }

        .students-section {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            padding: 60px 0;
            background: url('https://res.cloudinary.com/droimgtqi/image/upload/v1676066643/ukodesh-media/Vector_nvuwqr.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 1100px;

            .landing-wrapper {
                max-width: 1356px;

                @media screen and (max-width:1280px) {
                    max-width: 1048px;
                }

                @media screen and (max-width:430px) {
                    text-align: center;
                }

            }

        }

        .students-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            box-sizing: border-box;
            padding: 60px 0;

            @media screen and (max-width:1024px) {
                padding: 60px;
                grid-template-columns: 1fr;
            }

            @media screen and (max-width:430px) {
                padding: 0;
            }
        }


    }

    .gradient-title {
        background: linear-gradient(#562ff4, #43e8b6);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
    }

    .white-font {
        color: white;
    }

    .f-24 {
        font-size: 20px;
    }

    .f-10 {
        font-size: 10px;
    }

    .f-12 {
        font-size: 12px;
    }

    .f-18 {
        font-size: 18px;
    }

    .red-text {
        color: rgb(179, 26, 26);
    }

    .grey-text {
        color: rgb(114, 114, 114);
    }

    .mt-2 {
        margin-top: 20px;
    }

    .yellow-text {
        color: #ec9f0a;
    }


    .landing-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 1273px;
        width: 100%;
        padding: 0 48px;
        box-sizing: border-box;

        @media screen and (max-width:1024px) {
            flex-direction: column;
        }
    }

    .secondary-color {
        color: #8191ab;

    }

    .capitalize {
        text-transform: capitalize;
    }
}
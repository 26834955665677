.features-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 120px;

  .right-features {
    width: 40%;
    transition: all ease-in-out 0.2s;

    @media screen and (max-width: 1440px) {
      width: 50%;
      transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1222px) {
      box-sizing: border-box;
      width: 60%;
    }

    @media screen and (max-width: 430px) {
      padding: 0 40px;
      box-sizing: border-box;
      width: 100%;
    }

    @media screen and (min-width: 1440px) {
      width: 40%;
      transition: all ease-in-out 0.2s;
    }
  }
  .left-features {
    width: 40%;

    @media screen and (max-width: 1222px) {
      padding: 0 40px;
      box-sizing: border-box;
      width: 70%;
    }

    @media screen and (max-width: 430px) {
      padding: 0 40px;
      box-sizing: border-box;
      width: 100%;
    }
  }
}

.features-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.feature-line {
  display: flex;
  align-items: center;
  justify-content: center;


  .feature-img {
    margin-right: 10px;
  }
}

.feature-line:not(:last-child) {
  margin-bottom: 40px;
}



.inner-features {
  max-width: 1243px;
  width: 100%;
}

.ft-img {
  margin-right: 120px;
  @media screen and (max-width: 1222px) {
    margin-right: 0;
    margin-bottom: 80px;
  }
}

.rs-image {
  @media screen and (max-width: 912px) {
    width: 400px;
  }

  @media screen and (max-width: 590px) {
    width: 340px;
  }

  @media screen and (max-width: 430px) {
    width: 280px;
  }
}

.featured-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1222px) {
    flex-direction: column;
  }
}
.background-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.feature-text {
  text-align: left;

}

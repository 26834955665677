.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  background: #fff;
  flex-direction: column;
  max-width: 320px;
  max-height: 480px;

  @media screen and (max-width: 1024px) {
    max-width: 400px;
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    max-width: 300px;
    width: 100%;
  }

  .card-header {
    margin-top: -20px;
  }

  .card-body {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    flex-direction: column;
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 36px 0;
    a {
      padding: 18px 29px;
      background: #0075ff;
      border-radius: 100px;
      color: white;
      transition: all ease-in-out 0.2s;
    }
    a:hover {
      padding: 18px 29px;
      background: #055bbd;
      border-radius: 100px;
      color: white;
      transition: all ease-in-out 0.2s;
    }
  }
}

.card-container:not(:last-child) {
  margin-right: 80px;

  @media screen and (max-width:1024px) {
    margin-right: 0;
    margin-bottom: 50px;
  }
}

.switcher-language {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #f7f7f7;
    padding: 12px;
    box-sizing: border-box;
    transition: all ease-in-out .2s;
    margin-left: 24px;
    cursor: pointer;

    @media screen and (max-width:1024px) {
        margin-left: 0;
        margin-top:24px;
    }


    .language-mh {
        margin: 0 6px;
        text-transform: uppercase;
        font-size: 14px;
    }

    .switch-icon {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 8px;

        span {
            width: 8px;
            height: 2px;
            transition: 0.1s ease-in-out;
            background: #2e3035;
        }
    }
}

.switcher-language:hover {
    transition: all ease-in-out .2s;
    background: #eaeaea;
}

.language-selector{
    overflow: hidden;
    position: absolute;
    transform: translate3d(11px, 76px, 0px);
    will-change: transform;
    background: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    top: 5%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 110px;
    right: 1;
    padding: 8px;

    @media screen and (max-width:1024px) {
        position: absolute;
        top:52%;
        left:50%;
        transform: translate(-50%, 180%);
    }
}

.language-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

.language-detail{
    width: 100%;
    display: flex;
    border-radius: 6px;
    padding: 8px 0 8px 6px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    .language-img{
        width: 20px;
        margin-right: 8px;
    }
}

.language-detail:hover{
    background: #f4f4f4;
    transition: all ease-in-out 0.2s;
}

.language-detail:not(:last-child){
    margin-bottom: 6px;
}

.student-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    padding: 32px;
    flex-direction: column;
    width: 400px;
    box-sizing: border-box;
    margin-bottom: 24px;

    @media screen and (min-width: 1440px) {
        width: 400px;
        transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1440px) {
        width: inherit;
        transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1280px) {
        align-items: center;
        text-align: center;
        width: 260px;
        transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        transition: all ease-in-out 0.2s;
    }

    .card-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        .picture-wrapper {
            margin-top: 20px;

            @media screen and (max-width: 768px) {
                margin-right: 0;
            }
        }
    }

    .card-content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        @media screen and (max-width: 1280px) {
            align-items: center;
        }

        .title-review {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            text-align: center;
        }

        .text-review {
            font-weight: 400;
            font-size: 14px;
        }
    }
}

.review-details {

    @media screen and (max-width: 768px) {
        align-items: center;
    }

    .name {
        font-weight: bold;
    }

    .state {
        font-weight: bold;
    }
}

.student-card:not(:last-child) {
    margin-right: 30px;
    @media screen and (max-width: 912px) {
      margin-right: 0;
      transition: all ease-in-out 0.2s;
    }
  }
.certified-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#4367e8, #2fc0f4);
  margin-top: 40px;
  box-sizing: border-box;
  padding: 24px;

  .inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1243px;
    flex-direction: row;
    margin: 88px 0;
    width: 100%;
    box-sizing: border-box;


    @media screen and (max-width: 1280px) {
      max-width: 820px;
    }

    @media screen and (max-width: 912px) {
      flex-direction: column;
      text-align: center;
    }
  }
}

.max-w-400 {
  max-width: 400px;
}

.cert-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3c495c;
  background: white;
  padding: 24px;
  border-radius: 100px;
  box-sizing: border-box;

  @media screen and (max-width: 912px) {
    margin-top: 24px;
  }
}

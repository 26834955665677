.whats-button {
  display: flex;
  position: fixed;
  border-radius: 1000px;
  right: 0;
  bottom: 0;
  padding: 10px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin: 20px;
  background: #25d366;
  z-index: 1000;
  padding: 18px;

  .inner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .xxs-text {
      font-size: 10px;
      color: #fff;
    }
  }
}

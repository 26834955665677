.business-languages {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 24px 48px;
  }

  .languages-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1243px;

    .languages-desc {
      width: 100%;
      max-width: 720px;
      text-align: center;
    }

    .flags-wrapper{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
  
      .flag-col:not(:last-child){
        margin-right: 24px;
      }
  
      .flag-col{
        img{
          margin-bottom: 4px;
        }
      }
  
    }
  }
}


.levels-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .levels-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .levels-header{margin-bottom: 24px;}

    .levels-body {
      display: flex;
      box-sizing: border-box;
    }
  }

  .levels-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  .levels-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
    box-sizing: border-box;

    .footer-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: 80px;
      padding: 20px;
      box-sizing: border-box;
      text-align: center;
      height: 80px;
      transition: all ease-in-out .2s;
    }

  }
}

.level-font {
  font-size: 24px;
  font-weight: bold;
}


.levels-mobile{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;

  .levels-footer{
    padding:24px;
    text-align: center;
  }
}

.not-found-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 536px;

  .inner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1243px;
    text-align: center;

    @media screen and (max-width:768px) {
        padding: 0 48px;
    }

    .error-big {
        font-size: 60px;
        font-weight: bold;
      }

      .error-md{
        font-size: 32px;
        font-weight: 500;
      }

      .error-sad{
        font-size: 18px;
        font-weight: 200;
      }
  }
}

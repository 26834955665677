.business-levels {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .levels-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #447294;
    width: 100%;
    padding: 60px;
    box-sizing: border-box;

    @media screen and (max-width: 912px) {
      padding: 48px 0;
    }

    .levels-wrapper {
      max-width: 1243px;
      width: 100%;

      .active-bar-business {
        background: #447294;
        transition: all ease-in-out 0.2s;
      }
      .unactive-bar-business {
        background: #447294;
        transition: all ease-in-out 0.2s;
      }

      .active-element-business {
        background: #fff;
        span {
          color: #447294;
        }
      }

      .unactive-element-business {
        background: #e9f0f4;
        span {
          color: #8b9cbd;
        }
      }

      .active-selector-business {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        background: #ff5353;
      }

      .unactive-selector-business {
        width: 10px;
        height: 10px;
        border-radius: 100px;
        background: #447294;
      }
    }
  }
}

.competencies-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  .competencies-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    transition: all ease-in-out 0.2s;

    @media screen and (max-width: 912px) {
      overflow: hidden;
      transition: all ease-in-out 0.2s;
    }
  }

  .competencies-wrapper img.background {
    width: 100%;
    box-sizing: border-box;
    transition: all ease-in-out 0.2s;

    @media screen and (max-width: 912px) {
      width: auto;
      transition: all ease-in-out 0.2s;
    }
  }

  .competencies-wrapper img + .compentencies-content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    left: 0;
  }

  .compentencies-content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 50px;
  }
}

.overlayed-img {
  position: absolute;
  left: 0;
  mix-blend-mode: overlay;
  margin-left: 60px;
  width: 12%;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    margin-left: 60px;
    width: 12%;
  }

  @media screen and (max-width: 912px) {
    display: none;
    margin-left: 60px;
    width: 12%;
  }
}

.positioned-laptop {
  position: absolute;
  margin-right: 50px;
  right: 0;
  width: 20%;

  @media screen and (max-width: 1024px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 912px) {
    margin-right: 0;
    width: 50%;
    position: relative;
  }
}

.list-wrapper {
  margin-top: 60px;

  @media screen and (max-width: 912px) {
    margin-top: 30px;
    box-sizing: border-box;
    padding: 20px;
  }

  li {
    column-gap: 4px;
    margin-bottom: 20px;
    text-align: center;

    img {
      margin-bottom: -6px;
    }

    b {
      color: white;
    }
  }
}

.auxiliar-bg {
  position: absolute;
  background: white;
  left: 0;
  width: 100%;
  height: 50%;
  bottom: 0;
  z-index: -1;
}

.below-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 912px) {
    align-items: center;
    flex-direction: column;
  }
}

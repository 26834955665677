.partners-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1253px;

  @media screen and (max-width: 912px) {
    flex-direction: column;
    justify-content: center;
  }

  .partner-tag{

    @media screen and (min-width: 1440px) {
      transition: all ease-in-out 0.2s;
      max-width: 200px;
    }

    @media screen and (max-width: 1440px) {
      width: 15%;
      transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1024px) {
      margin-right: 0;
      width: 166px;
    }

    @media screen and (max-width: 912px) {
      margin-right: 0;
      width: 206px;
    }

  }

  .partner-tag:not(:last-child) {
    @media screen and (min-width: 1440px) {
      transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1440px) {
      width: 15%;
      transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1024px) {
      margin-right: 0;
      width: 166px;
    }

    @media screen and (max-width: 912px) {
      margin-right: 0;
      width: 206px;
      margin-bottom: 40px;
    }
  }
}

body{
  background-color: white;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  padding: 12px 20px;
  background: #0075ff;
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.login-btn:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  padding: 12px 20px;
  background: #055bbd;
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.login-btn-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 100px;
  padding: 12px 20px;
  background: #fff;
  color: #3c495c;
  font-size: 14px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.login-btn-footer:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 100px;
  padding: 12px 20px;
  background: #3c495c;
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.primary-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 100px;
  padding: 8px 12px 8px 24px;
  background: #0075ff;
  color: white;
  font-size: 18px;
  box-sizing: border-box;
  min-width: 240px;
  font-weight: bold;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 1024px) {
    min-width: 200px;
    font-size: 16px;
    padding: 8px 12px 8px 24px;
    transition: all ease-in-out 0.2s;
    img {
      transition: all ease-in-out 0.2s;
      width: 32px;
    }
  }
}

.primary-btn:hover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 100px;
  padding: 8px 12px 8px 24px;
  background: #055bbd;
  color: white;
  font-size: 18px;
  box-sizing: border-box;
  min-width: 240px;
  font-weight: bold;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 1024px) {
    min-width: 200px;
    font-size: 16px;
    padding: 8px 12px 8px 24px;
    transition: all ease-in-out 0.2s;
    img {
      transition: all ease-in-out 0.2s;
      width: 32px;
    }
  }
}

.test-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0075ff;
  border-radius: 100px;
  padding: 12px 20px;
  background: white;
  color: #0075ff;
  font-size: 12px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
}

.test-btn-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 100px;
  padding: 12px 20px;
  background: #3c495c;
  color: #fff;
  font-size: 12px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.test-btn-footer:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 100px;
  padding: 12px 20px;
  background: white;
  color: #3c495c;
  font-size: 12px;
  box-sizing: border-box;
  width: 150px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}

.helper-text {
  color: #ec9f0a;
  font-size: 20px;
  font-weight: 600;

  @media screen and (max-width: 1292px) {
    font-size: 19px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 1122px) {
    font-size: 18px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 1024px) {
    font-size: 17px;
    transition: all ease-in-out 0.2s;
  }
}

.big-helper-text {
  color: #ec9f0a;
  font-size: 48px;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 912px) {
    font-size: 24px;
    transition: all ease-in-out 0.2s;
  }
}

.hero-title {
  font-size: 56px;
  font-weight: bold;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 1292px) {
    font-size: 48px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 1122px) {
    font-size: 40px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 1024px) {
    font-size: 36px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 884px) {
    font-size: 28px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 485px) {
    font-size: 24px;
    transition: all ease-in-out 0.2s;
  }
  @media screen and (max-width: 425px) {
    font-size: 20px;
    transition: all ease-in-out 0.2s;
  }
}

.capitalize {
  text-transform: capitalize;
}

.spacer {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 430px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.title-md {
  font-size: 36px;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 430px) {
    font-size: 24px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 375px) {
    font-size: 20px;
    transition: all ease-in-out 0.2s;
  }
}

.text-md {
  font-size: 24px;

  @media screen and (max-width: 820px) {
    font-size: 20px;
    transition: all ease-in-out 0.2s;
  }

  @media screen and (max-width: 425px) {
    max-width: 60%;
    font-size: 16px;
    transition: all ease-in-out 0.2s;
  }
}

.white-font {
  color: white;
}

.gradient-title {
  background: linear-gradient(#562ff4, #43e8b6);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.dark-gradient-title {
  background: linear-gradient(#5540ef, #004b4f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.secondary-gradient {
  background: linear-gradient(180deg, #6350ff, #a32fff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-weight: bold;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.mt-8 {
  margin-top: 80px;
}

.mb-8 {
  margin-bottom: 80px;
}

.mt-9 {
  margin-top: 90px;
}

.mt-10 {
  margin-top: 100px;
}

.mt-11 {
  margin-top: 110px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-1{margin-right: 10px;}
.mr-2{margin-right: 20px;}
.mr-3{margin-right: 30px;}
.mr-4{margin-right: 40px;}
.mr-5{margin-right: 50px;}
.mr-6{margin-right: 60px; @media screen and (max-width: 1024px) {
  margin-right: 0;
}}
.mr-7{margin-right: 70px;}

.ml-1{margin-left: 10px;}
.ml-2{margin-left: 20px;}
.ml-3{margin-left: 30px;}
.ml-4{margin-left: 40px;}
.ml-5{margin-left: 50px;}
.ml-6{margin-left: 60px; @media screen and (max-width: 1024px) {
  margin-left: 0;
}}
.ml-7{margin-left: 70px;}

.mb-70 {
  margin-bottom: 70px;

  @media screen and (max-width: 912px) {
    margin-bottom: 0;
  }
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-6 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ph-1 {
  padding: 0 10px;
}
.ph-2 {
  padding: 0 20px;
}
.ph-3 {
  padding: 0 30px;
}
.ph-4 {
  padding: 0 40px;
}
.ph-5 {
  padding: 0 50px;
}
.ph-6 {
  padding: 0 60px;
}
.ph-7 {
  padding: 0 70px;
}
.ph-8 {
  padding: 0 80px;
}
.ph-9 {
  padding: 0 90px;
}
.ph-10 {
  padding: 0 100px;
}

.row {
  display: flex;
}

.directioner {
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.centered-text {
  text-align: center;
}

.centered-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 912px) {
    flex-direction: column;
  }
}

.arounded-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-sizing: border-box;
}

.spaced-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.mr-150 {
  margin-right: 150px;

  @media screen and (max-width: 912px) {
    margin-right: 0;
  }
}

.ml-150 {
  margin-left: 150px;

  @media screen and (max-width: 912px) {
    margin-left: 0;
  }
}

.ml-12 {
  margin-left: 120px;

  @media screen and (max-width: 1222px) {
    margin-left: 0;
  }
}

.spaced-xxs {
  column-gap: 8px;
}

.spaced-xs {
  column-gap: 16px;
}

.spaced-sm {
  column-gap: 32px;
}

.spaced-md {
  column-gap: 48px;
}

.spaced-l {
  column-gap: 64px;
}

.spaced-xl {
  column-gap: 80px;
}

.spaced-xxl {
  column-gap: 96px;
}

.spaced-xxxl {
  column-gap: 112px;
}

.spaced-xxxx {
  column-gap: 128px;
}

.spaced-big {
  column-gap: 150px;
}

.spaced-xbig {
  column-gap: 250px;
}

.white-bg {
  background: white;
}

.blue-bg {
  background: #0075ff;
  color: white;
}

.skill-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 18px 24px;
  font-weight: bold;

  @media screen and (max-width: 912px) {
    margin-bottom: 40px;
  }

  img {
    width: 60px;
  }
}

.xl-width {
  min-width: 200px;
}

.button {
  cursor: pointer;
}

.card-spacer {
  margin-bottom: 80px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 50px;
  }
}

.low-blue-bg {
  background: #ecf5ff;
}

.reversed {
  @media screen and (max-width: 1222px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse !important;
  }
}

.full-row{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @media screen and (max-width:1024px) {
    align-items: center;
    flex-direction: column;
  }
}

.bsns-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 100px;
  padding: 8px 12px 8px 24px;
  background: #447294;
  color: white;
  font-size: 18px;
  box-sizing: border-box;
  min-width: 240px;
  font-weight: bold;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 1024px) {
    min-width: 200px;
    font-size: 16px;
    padding: 8px 12px 8px 24px;
    transition: all ease-in-out 0.2s;
    img {
      transition: all ease-in-out 0.2s;
      width: 32px;
    }
  }
}

.bsns-btn:hover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 100px;
  padding: 8px 12px 8px 24px;
  background: #345772;
  color: white;
  font-size: 18px;
  box-sizing: border-box;
  min-width: 240px;
  font-weight: bold;
  transition: all ease-in-out 0.2s;

  @media screen and (max-width: 1024px) {
    min-width: 200px;
    font-size: 16px;
    padding: 8px 12px 8px 24px;
    transition: all ease-in-out 0.2s;
    img {
      transition: all ease-in-out 0.2s;
      width: 32px;
    }
  }
}


.big-blue-text {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: #447294;

  @media screen and (max-width: 912px) {
    font-size: 24px;
  }
}

.divider{
  width: 100%;
  max-width: 300px;
  border: .1px solid #a8a8a8;
}

.txt-ctc{
  font-weight: 500;
  font-size: 20px;
}

.flex{
  display: flex;
}

.flex-col{
  flex-direction: column;
}